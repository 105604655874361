// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dojazd-index-js": () => import("./../src/pages/dojazd/index.js" /* webpackChunkName: "component---src-pages-dojazd-index-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../src/pages/kontakt/potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-notaries-page-js": () => import("./../src/templates/notaries-page.js" /* webpackChunkName: "component---src-templates-notaries-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-services-page-js": () => import("./../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

